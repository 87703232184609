import { configureStore } from "@reduxjs/toolkit";

import storeSlice from './Redux/Slices/StoreSlices';
import couponSlices from "./Redux/Slices/CouponSlices";

const store = configureStore({
  reducer: {
      store : storeSlice,
      coupon : couponSlices
    },
   
})

export default store