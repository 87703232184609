import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const Coupons = createAsyncThunk("admin/coupons", async () => {

    const url = `${process.env.REACT_APP_ADMIN_URL}/webmaster/get-all-coupons`
      const res = await fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      return res.json()
})


const couponSlice = createSlice({
    name: 'coupon',
    initialState: {
        isLoading: false,
        isError: null,
        data: null,
    },
    extraReducers : (builder) => {
        builder.addCase(Coupons.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(Coupons.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })

        builder.addCase(Coupons.rejected, (state, action) => {
            state.isLoading = false
            state.isError = action.error.message
        })
    }
})

export default couponSlice.reducer;