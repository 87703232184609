import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAllStore = createAsyncThunk("store/getAllStore", async () => {

    const url = process.env.REACT_APP_ADMIN_URL + `/webmaster/store`
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        cache: "no-cache",
    })
    return res.json()
})

const storeSlice = createSlice({
    name: 'store',
    initialState: {
        isLoading: false,
        isError: null,
        data: []
    },
    extraReducers: (builder) => { 
        
        // get All Store
        builder.addCase(getAllStore.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(getAllStore.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })

        builder.addCase(getAllStore.rejected, (state, action) => {
            state.isLoading = false
            state.isError = action.error.message
        })
    }
})

export default storeSlice.reducer;